import api from "../axios_service.js";

export async function getLeadsStaffAnotacoes(id) {
  const response = await api.get(`/staff/leads-anotacoes/${id}`);
  return response.data.result;
}
export function postAnotacao(anotacao) {
  return api.post(`/staff/leads-anotacoes/add`, anotacao);
}
export function putAnotacao(id, anotacao) {
  return api.put(`/staff/leads-anotacoes/${id}`, anotacao);
}
