<template>
  <v-card flat :loading="loading">
    <v-card-title :class="{ 'px-0': $vuetify.breakpoint.smAndDown }">
      <v-btn
        depressed
        color="primary"
        class="white--text"
        :small="$vuetify.breakpoint.smAndDown"
        :class="{ 'ml-auto mr-4': $vuetify.breakpoint.smAndUp }"
        @click.stop="dialogAssuntos = true"
      >
        <v-icon dark left>mdi-subtitles-outline</v-icon>assuntos</v-btn
      >

      <v-btn
        depressed
        color="xbColor"
        class="white--text"
        :small="$vuetify.breakpoint.smAndDown"
        :class="{ 'ml-auto': $vuetify.breakpoint.smAndDown }"
        @click.stop="goToAddAnotacao"
      >
        <v-icon left>mdi-plus</v-icon>Adicionar
      </v-btn>
    </v-card-title>

    <v-card-text class="">
      <v-card
        flat
        color="#F5F5F5"
        :key="index"
        v-for="(anotacao, index) in anotacoes"
        :class="{ 'mb-4': index != anotacoes.length - 1 }"
        @click="goToAnotacao(anotacao)"
      >
        <v-card-title
          class="text-body-2 d-flex align-center"
          :class="{ 'justify-center': $vuetify.breakpoint.smAndDown }"
        >
          <div
            :style="$vuetify.breakpoint.smAndDown ? 'width: 100%' : ''"
            class="d-flex align-center"
            :class="{ 'justify-space-between': $vuetify.breakpoint.smAndDown }"
          >
            <v-sheet
              class="py-1 px-2 white--text rounded mr-3"
              style="width: fit-content"
              :color="anotacao.assunto_cor ? anotacao.assunto_cor : 'black'"
            >
              {{ anotacao.assunto_descricao }}
            </v-sheet>
            <div class="text-caption">
              {{ anotacao.created_at | dateFormat("dd/MM/yyyy HH:mm:ss") }}
            </div>
          </div>

          <div
            :class="{
              'ml-auto': $vuetify.breakpoint.smAndUp,
              'mt-2': $vuetify.breakpoint.smAndDown,
            }"
          >
            {{ anotacao.nome_criador }}
          </div>
        </v-card-title>
        <v-card-text>
          {{ anotacao.obs }}
        </v-card-text>
      </v-card>
    </v-card-text>
    <DialogAssuntos
      v-if="dialogAssuntos"
      :dialogAssuntos.sync="dialogAssuntos"
    />
    <AddEditAnotacoes
      v-if="dialogAddEditAnotacoes"
      :dialogAddEditAnotacoes.sync="dialogAddEditAnotacoes"
      :item="anotacao"
      :edit="edit"
      :usuarioLeed="user_id"
      @fetch-anotacao="getUsuarioLeadsAnotacoes"
    />
  </v-card>
</template>

<script>
import { getLeadsStaffAnotacoes } from "@/api/staff/leeds_anotacoes.js";
export default {
  name: "AnotacoesLeeds",

  props: {
    user_id: {
      type: [Number, String],
      require: true,
    },
  },

  data() {
    return {
      loading: true,
      edit: false,
      anotacoes: [],
      anotacao: {},
      dialogAssuntos: false,
      dialogAddEditAnotacoes: false,
    };
  },

  components: {
    DialogAssuntos: () => import("./DialogAssuntos.vue"),
    AddEditAnotacoes: () => import("./components/AddEditAnotacoes.vue"),
  },

  computed: {
    headers() {
      return [
        {
          text: "data",
          width: "90px",
          sortable: false,
          value: "updated_at",
        },
        {
          text: "Usuario",
          width: "160px",
          sortable: false,
          value: "nome_criador",
        },
        {
          text: "Assunto",
          width: "90px",
          sortable: false,
          value: "assunto_descricao",
        },
        {
          text: "Observação",
          sortable: false,
          value: "obs",
        },
      ];
    },
  },

  methods: {
    goToAnotacao(item) {
      this.anotacao = { ...item };
      this.edit = true;
      this.dialogAddEditAnotacoes = true;
    },
    goToAddAnotacao() {
      this.anotacao = {};
      this.edit = false;
      this.dialogAddEditAnotacoes = true;
    },
    getUsuarioLeadsAnotacoes() {
      this.loading = true;
      return getLeadsStaffAnotacoes(this.user_id)
        .then((response) => {
          this.anotacoes = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  async mounted() {
    await this.getUsuarioLeadsAnotacoes();
  },
};
</script>

<style></style>
